// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  // // URL of development API
  BASE_API_URL: 'https://csibackendsln-5-dev-as.azurewebsites.net/',
  //     BASE_API_URL: 'http://localhost:8080/',
  //     //BASE_API_URL: 'https://csibackendsln-5-dev-as-staging.azurewebsites.net/',
  //    //BASE_API_URL: 'https://localhost:44362/',
  BASE_API_URL_PDF: 'https://csibackendsln-5-dev-as.azurewebsites.net/Pdfs/',
  //    BASE_API_URL_PDF: 'http://localhost:8080/Pdfs/',
  //    // BASE_API_URL_PDF: 'https://csibackendsln-5-dev-as-staging.azurewebsites.net/Pdfs/',
  //    //BASE_API_URL_PDF: 'https://localhost:44362/Pdfs/',
  // production: false,
  // URL of development API
  //BASE_API_URL: 'https://csibackendsln-5-dev-as.azurewebsites.net/',
  //BASE_API_URL: 'https://csibackendsln-5-dev-as-staging.azurewebsites.net/',
  //BASE_API_URL: 'https://localhost:44362/',
  //BASE_API_URL_PDF: 'https://csibackendsln-5-dev-as.azurewebsites.net/Pdfs/',
  // BASE_API_URL_PDF: 'https://csibackendsln-5-dev-as-staging.azurewebsites.net/Pdfs/',
  //BASE_API_URL_PDF: 'https://localhost:44362/Pdfs/',
  production: false,
};

/*
 * For easier debug
 ging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
