import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AllServicesService {
  public headers: Headers;
  public userProfileData = new ReplaySubject<Array<Object>>();
  public userUpdateData = new ReplaySubject<Array<Object>>();
  public notificationData = new ReplaySubject<Array<Object>>();
  public unitDetails = new ReplaySubject<Array<Object>>();
  constructor(@Inject(HttpClient) public http: HttpClient,
    private route: Router, private apiService: ApiService) { };

  login(d: any): Observable<any> {
    return this.apiService.postData('api/Account/CreateToken', d, null)
  }

  Register(model: any) {
    return this.apiService.postData('api/Account/Register', model, null);
  }
  AdminRegister(model: any) {
    return this.apiService.postData('api/Account/AdminRegister', model, null);
  }
  AdminUpdateRegister(model: any) {
    return this.apiService.postData('api/Account/UpdateUserData', model, null);
  }
  GetUserRolesById(model: any) {
    return this.apiService.postData('api/Account/GetUserRolesById', model, null);
  }
  AddProduct(model: any) {
    return this.apiService.postData('api/Equipment/AddEditEquipment', model, null);
  }
  CheckDuplication(model: any) {
    return this.apiService.postData('api/Common/CheckIfCodeExist', model, null);
  }
  GetData(model: any) {
    return this.apiService.postData('api/Equipment/GetAllEquipmentsList', model, null);
  }
  EditProduct(id: number) {
    return this.apiService.getData('api/Equipment/GetEquipmentById?id=' + id, null);
  }
  generateReport(id: number) {
    return this.apiService.getEquipmentReport('api/Equipment/GetEquipmentByIdForReport?id=' + id);
  }
  DeleteProduct(id: number) {
    return this.apiService.postData('api/Equipment/DeleteEquipment?id=' + id, null, null);
  }
  ActiveProduct(id: number) {
    return this.apiService.postData('api/Equipment/ActiveEquipmentById?id=' + id, null, null);
  }
  GetAllEventByEquipmentIdOpenSchedule(id: number) {
    return this.apiService.postData('api/events/GetAllEventByEquipmentIdOpenSchedule?equipmentId=' + id, null, null);
  }
  GetEquipmentOwnerList(model: any) {
    return this.apiService.postData('api/EquipmentOwner/GetAllEquipmentOwner', model, null);
  }
  GetAlluser() {
    return this.apiService.getData('api/Account/GetAllUser', null);
  }
  GetAllSalesUser() {
    return this.apiService.getData('api/Account/GetAllSalesUser', null);
  }

  GetAllSalesUserCount() {
    return this.apiService.getData('api/Account/GetAllUserCount', null);
  }
  // time tracking 
  GetUserDetails(model: any) {
    return this.apiService.postData('api/WorkOrder/GetTimeSheetByUserFilters', model, null);
  }
  GetUserLabelDetails(model: any) {
    return this.apiService.postData('api/WorkOrder/GetTimeSheetByUserFiltersByWO', model, null);
  }


  //***********************purchaseOrder */
  AddPurchaseOrder(model: any) {
    return this.apiService.postData('api/UnitOfMeasure/AddEditPurchaseOrder', model, null);
  }
  GetAllPurchaseOrder(model: any) {
    return this.apiService.postData('api/UnitOfMeasure/GetAllPurchaseOrder', model, null);
  }
  EditPurchaseOrder(id: number) {
    return this.apiService.getData('api/UnitOfMeasure/GetPurchaseOrderById?id=' + id, null);
  }
  DeletePurchaseOrder(id: number) {
    return this.apiService.postData('api/UnitOfMeasure/DeletePurchaseOrderById?id=' + id, null, null);
  }
  // ********************** Unit Inspection

  GetunitInfectiondata(model: any) {
    return this.apiService.postData('api/UnitInspection/GetAllUnitInspection', model, null);
  }
  AddEditUnitInspection(model: any) {
    return this.apiService.postData('api/UnitInspection/AddEditUnitInspection', model, null);
  }
  AddEditUnitInspectionDetail(model: any) {
    return this.apiService.postData('api/UnitInspection/AddEditUnitInspectionDetail', model, null);
  }
  DeleteUnitInspection(id: number) {
    return this.apiService.postData('api/UnitInspection/DeleteUnitInspectionById?id=' + id, null, null);
  }
  DeleteUnitInspectionDetailById(id: number) {
    return this.apiService.postData('api/UnitInspection/DeleteUnitInspectionDetailById?id=' + id, null, null);
  }
  ActivateUnitInspectionDetail(id: number, active: boolean) {
    return this.apiService.postData('api/UnitInspection/ActivateUnitInspectionDetail?id=' + id + '&active=' + active, null, null);
  }
  SetSortOrderParent(model) {
    return this.apiService.postData('api/UnitInspection/SetSortOrder', model, null);
  }
  // ********************** Category

  AddCategory(model: any) {
    return this.apiService.postData('api/Category/AddCategory', model, null);
  }
  GetCategoryList(model: any) {
    return this.apiService.postData('api/Category/GetAllCategoryList', model, null);
  }
  DeleteCAtegory(id: number) {
    return this.apiService.postData('api/Category/DeleteCategory?id=' + id, null, null);
  }
  GetCategoryById(id: number) {
    return this.apiService.postData('api/Category/GetCategoryById?id=' + id, null, null);
  }
  GetCategoryByIdEqEdit(id: number) {
    return this.apiService.postData('api/Category/GetCategoryByIdEqEdit?id=' + id, null, null);
  }
  GetCategoryByIdWithoutPagination(id: number) {
    return this.apiService.postData('api/Category/GetCategoryByIdWithoutPagination?id=' + id, null, null);
  }
  GetCategorylistPaging() {
    return this.apiService.getData('api/Category/GetAllCategoryListWithOutPagination', null);
  }
  GetOptionListWithoutPaging(model: any) {
    return this.apiService.postData('api/EquipmentOptionTypes/GetAllEqOptionTypeListWoptions', model, null);
  }

  // ********************* Vendor
  AddVendor(model: any) {
    return this.apiService.postData('api/EquipmentVendor/AddEquipmentVendor', model, null);
  }
  DeleteVendor(id: number) {
    return this.apiService.postData('api/EquipmentVendor/DeleteEqVendor?id=' + id, null, null);
  }
  DeleteVendorContact(id: number) {
    return this.apiService.postData('api/EquipmentVendor/DeleteEqVendorContact?id=' + id, null, null);
  }
  DeleteCustomerContact(id: number) {
    return this.apiService.postData('api/EquipmentOwnerContact/DeleteEquipmentOwnerContactById?id=' + id, null, null);
  }
  GetVendorContact(id: number) {
    return this.apiService.postData('api/EquipmentVendor/GetVendorContactById?id=' + id, null, null);
  }
  GetVendorList(model: any) {
    return this.apiService.postData('api/EquipmentVendor/GetAllEqVendorList', model, null);
  }
  UpdateEvent(model: any) {
    return this.apiService.postData('api/events/UpdateEvent', model, null);
  }
  // ********************* Labor Rates
  AddLabor(model: any) {
    return this.apiService.postData('api/ServiceLaborRate/AddEditServiceLaborRate', model, null);
  }
  DeleteLabor(id: number) {
    return this.apiService.postData('api/ServiceLaborRate/DeleteServiceLaborRateById?id=' + id, null, null);
  }
  GetLaborList(model: any) {
    return this.apiService.postData('api/ServiceLaborRate/GetAllServiceLaborRate', model, null);
  }
  // ********************* Service Contract
  AddServiceContract(model: any) {
    return this.apiService.postData('api/ServiceContract/AddServiceContract', model, null);
  }
  DeleteServiceContract(id: number) {
    return this.apiService.postData('api/ServiceContract/DeleteServiceContractById?id=' + id, null, null);
  }
  GetServiceContractList(model: any) {
    return this.apiService.postData('api/ServiceContract/GetAllServiceContract', model, null);
  }
  // ********************* Unit Of Measuere
  AddUnitOfMeasure(model: any) {
    return this.apiService.postData('api/UnitOfMeasure/AddEdiUnitOfMeasure', model, null);
  }
  DeleteUnitOfMeasure(id: number) {
    return this.apiService.postData('api/UnitOfMeasure/DeleteUnitOfMeasureById?id=' + id, null, null);
  }
  GetUnitOfMeasureList(model: any) {
    return this.apiService.postData('api/UnitOfMeasure/GetAllUnitOfMeasure', model, null);
  }

  //********************Equipment Option Type
  AddOptionType(model: any) {
    return this.apiService.postData('api/EquipmentOptionTypes/AddEditEqOptionType', model, null);
  }
  DeleteOptionType(id: number) {
    return this.apiService.postData('api/EquipmentOptionTypes/DeleteEqOptionTypeById?id=' + id, null, null);
  }
  GetOptionTypeList(model: any) {
    return this.apiService.postData('api/EquipmentOptionTypes/GetEquipmentOptionTypes', model, null);
  }


  //   // ********************* Service Item Location
  AddServiceItemLocation(model: any) {
    return this.apiService.postData('api/ServiceItemLocation/AddEditServiceItemLocation', model, null);
  }
  DeleteServiceItemLocation(id: number) {
    return this.apiService.postData('api/ServiceItemLocation/DeleteServiceItemLocationById?id=' + id, null, null);
  }
  GetServiceItemLocationList(model: any) {
    return this.apiService.postData('api/ServiceItemLocation/GetAllServiceItemLocation', model, null);
  }
  GetServiceItemLocationById(id: number) {
    return this.apiService.getData('api/ServiceItemLocation/GetOptionById?id=' + id, null);
  }

  //   // ********************* Option
  AddOption(model: any) {
    return this.apiService.postData('api/EquipmentOption/AddEquipmentOption', model, null);
  }
  DeleteOption(id: number) {
    return this.apiService.postData('api/EquipmentOption/DeleteEqOption?id=' + id, null, null);
  }
  GetOptionList(model: any) {
    return this.apiService.postData('api/EquipmentOption/GetAllEqOptionList', model, null);
  }
  GetOptionItemById(id: number) {
    return this.apiService.getData('api/EquipmentOption/GetOptionById?id=' + id, null);
  }
  // *************************************** Owner
  AddOwner(model: any) {
    return this.apiService.postData('api/EquipmentOwner/AddEquipmentOwner', model, null);
  }
  DeleteOwner(id: number) {
    return this.apiService.postData('api/EquipmentOwner/DeleteEquipmentOwnerById?id=' + id, null, null);
  }
  ActiveDeactiveEquipmentOwner(model: any) {
    return this.apiService.postData('api/EquipmentOwner/ActiveDeactiveEquipmentOwner', model, null);
  }

  ActiveDeactiveVendor(model: any) {
    return this.apiService.postData('api/EquipmentVendor/ActiveDeactiveVendor', model, null);
  }
  GetOwnerList(model: any) {
    return this.apiService.postData('api/EquipmentOwner/GetAllEquipmentOwner', model, null);
  }
  GetCustomerContact(id: number) {
    return this.apiService.postData('api/EquipmentOwner/GetEquipmentOwnerById?id=' + id, null, null);
  }
  //********************** Event
  AddeventItem(model: any) {
    return this.apiService.postData('api/events/CreateEvent', model, null);
  }
  DeleteEventItem(id: number) {
    return this.apiService.postData('api/events/DeleteEvent?id=' + id, null, null);
  }
  GetEventItemList(model: any) {
    return this.apiService.postData('api/events/GetAllEventList', model, null);
  }
  GetEventItemById(id: number) {
    return this.apiService.postData('api/events/GetServiceItemById?id=' + id, null, null);
  }

  //********************** Service Item
  AddServiceItem(model: any) {
    return this.apiService.postData('api/ServiceItem/AddEditServiceItem', model, null);
  }
  AddEditJobCode(model: any) {
    return this.apiService.postData('api/ServiceItem/AddEditJobCode', model, null);
  }
  DeleteServiceItem(id: number) {
    return this.apiService.postData('api/ServiceItem/DeleteServiceItemById?id=' + id, null, null);
  }
  DeleteJobCode(id: number) {
    return this.apiService.postData('api/ServiceItem/DeleteJobCode?id=' + id, null, null);
  }
  GetServiceItemList(model: any) {
    return this.apiService.postData('api/ServiceItem/GetAllServiceItem', model, null);
  }
  GetAllJobCode(model: any) {
    return this.apiService.postData('api/ServiceItem/GetAllJobCode', model, null);
  }
  GetServiceItemById(id: number) {
    return this.apiService.postData('api/ServiceItem/GetServiceItemById?id=' + id, null, null);
  }
  GetJobCodeById(id: number) {
    return this.apiService.postData('api/ServiceItem/GetJobCodeById?id=' + id, null, null);
  }
  GetAllMeasurment(model: any) {
    return this.apiService.postData('api/Measurement/GetAllMeasurementList', model, null);
  }
  GetAllServiceItemtype() {
    return this.apiService.getData('api/ServiceItemType/GetAllServiceItemTypeList', null);
  }
  CreateScheduledEvent(id: number, date: any) {
    return this.apiService.postData('api/events/CreateScheduledEvent?eventId=' + id + '&dueDate=' + date, null, null);
  }
  //********************** Contact
  AddContact(model: any) {
    return this.apiService.postData('api/EquipmentManufacturerContact/AddEquipmentContact', model, null);
  }
  DeleteContact(id: number) {
    return this.apiService.postData('api/EquipmentManufacturerContact/DeleteEqContact?id=' + id, null, null);
  }
  GetContactList() {
    return this.apiService.getData('api/EquipmentManufacturerContact/GetAllEqContactList', null);
  }
  OnSearch(model: any) {
    return this.apiService.postData('api/EquipmentManufacturerContact/OnSearchContact', model, null);
  }
  //***************************** Service Location */

  AddServiceLocation(model: any) {
    return this.apiService.postData('api/ServiceLocation/AddEditServiceLocation', model, null);
  }

  GetServiceLocationList(model: any) {
    return this.apiService.postData('api/ServiceLocation/GetAllServiceLocation', model, null);
  }
  DeleteServiceLocation(id: number) {
    return this.apiService.postData('api/ServiceLocation/DeleteServiceLocationById?id=' + id, null, null);
  }
  //********************* */ Manifacturer
  AddManifacturer(model: any) {
    return this.apiService.postData('api/EquipmentManufacturer/AddEquipmentManufacturer', model, null);
  }
  DeleteManifacturer(id: number) {
    return this.apiService.postData('api/EquipmentManufacturer/DeleteEqManifacturer?id=' + id, null, null);
  }
  GetManifacturerList(model: any) {
    return this.apiService.postData('api/EquipmentManufacturer/GetAllEqManifacturerList', model, null);
  }
  //Dashboard
  GetDashboardData() {
    return this.apiService.getData('api/WorkOrder/GetAllCount', null);
  }
  //*******************************EquipmentAssembly */
  AddAssembly(model: any) {
    return this.apiService.postData('api/EquipmentAssembly/AddEditEquipmentAssembly', model, null);
  }
  DeleteAssembly(id: number) {
    return this.apiService.postData('api/EquipmentAssembly/DeleteEquipmentAssemblyById?id=' + id, null, null);
  }
  GetAssemblyList(model: any) {
    return this.apiService.postData('api/EquipmentAssembly/GetAllEquipmentAssembly', model, null);
  }
  //******************************* Equipment Location */
  AddLocation(model: any) {
    return this.apiService.postData('api/EquipmentLocation/AddEditEquipmentLocation', model, null);
  }
  DeleteLocation(id: number) {
    return this.apiService.postData('api/EquipmentLocation/DeleteEquipmentLocationById?id=' + id, null, null);
  }
  GetLocationList(model: any) {
    return this.apiService.postData('api/EquipmentLocation/GetAllEquipmentLocation', model, null);
  }
  GetAllEquipmentLocationDropdown() {
    return this.apiService.getData('api/EquipmentLocation/GetAllEquipmentLocationDropdown', null);
  }
  GetAllServiceContract(model: any) {
    return this.apiService.postData('api/ServiceContract/GetAllServiceContract', model, null);
  }
  GetAllunitStatus() {
    return this.apiService.getData('api/equipmentStatus/GetAllEquipmentStatus', null);
  }
  //*************************** Equipment Status */

  AddEquipmentStatus(model: any) {
    return this.apiService.postData('api/equipmentStatus/AddEditEquipmentStatus', model, null);
  }

  GetEquipmentStatusList(model: any) {
    return this.apiService.postData('api/equipmentStatus/GetAllEquipmentStatus', model, null);
  }
  DeleteEquipmentStatus(id: number) {
    return this.apiService.postData('api/equipmentStatus/DeleteEquipmentStatusById?id=' + id, null, null);
  }

  //****************************** Status */
  AddStatus(model: any) {
    return this.apiService.postData('api/EquipmentStatus/AddEditEquipmentStatus', model, null);
  }
  DeleteStatus(id: number) {
    return this.apiService.postData('api/EquipmentStatus/DeleteEquipmentStatusById?id=' + id, null, null);
  }
  GetStatusList(model: any) {
    return this.apiService.postData('api/EquipmentStatus/GetAllEquipmentStatus', model, null);
  }
  //*************************Zone */
  AddZone(model: any) {
    debugger;
    return this.apiService.postData('api/serviceZone/AddEditServiceZone', model, null);
  }
  DeleteZone(id: number) {
    return this.apiService.postData('api/serviceZone/DeleteServiceZoneById?id=' + id, null, null);
  }
  GetZoneList(model: any) {
    return this.apiService.postData('api/serviceZone/GetAllServiceZone', model, null);
  }
  //*************************Region */
  AddRegion(model: any) {
    return this.apiService.postData('api/serviceRegion/AddEditServiceRegion', model, null);
  }

  DeleteRegion(id: number) {
    return this.apiService.postData('api/serviceRegion/DeleteServiceRegionById?id=' + id, null, null);
  }
  GetRegionList(model: any) {
    return this.apiService.postData('api/serviceRegion/GetAllServiceRegion', model, null);
  }

  AddZoneCodeInRegion(model: any) {
    return this.apiService.getData('api/serviceRegion/GetServiceZoneCode', null);
  }


  // work Order Format
  GetWorkOrderFormat() {
    return this.apiService.getData('api/WorkOrderFormat/GetAllWorkOrderFormat', null);
  }


  //***********************  Service Contract Type*/
  GetServiceContractType() {
    return this.apiService.getData('api/ServiceContract/GetAllServiceContractList', null);
  }

  //********************Measurment
  AddMeasurment(model: any) {
    return this.apiService.postData('api/Measurement/AddEditMeasurement', model, null);
  }
  DeleteMeasurment(id: number) {
    return this.apiService.postData('api/Measurement/DeleteMeasurementById?id=' + id, null, null);
  }
  GetMeasurmentList(model: any) {
    return this.apiService.postData('api/Measurement/GetAllMeasurementList', model, null);
  }

  //********************OTP Verification
  otpVerification(model: any) {
    return this.apiService.postData('api/Account/OtpVerification', model, "");
  }

  //********************ForgeotPassword Verification
  ForgotPassword(model: any) {
    return this.apiService.postData('api/Account/ForgotPassword', model, "");
  }
  //********************ResetPassword Verification
  ResetPassword(model: any) {
    return this.apiService.postData('api/Account/ResetPassword', model, "");
  }
  //********Get Profile */
  GetMyProfile(model: any) {
    return this.apiService.postData('api/Account/GetMyProfile', model, "");
  }
  //******Upload Image */

  UploadUserImage(model: any) {
    return this.apiService.postData('api/Account/UploadProfileImages', model, "");
  }

  UpdateStatus(model: any) {
    return this.apiService.postData('api/Account/UpdateStatus', model, "");
  }
  ChangePassword(model: any) {
    return this.apiService.postData('api/Account/ChangePassword', model, "");
  }


  //*********Update Profile */
  UpdateProfile(model: any) {
    return this.apiService.postData('api/Account/UpdateProfile', model, "");
  }


  //**************************Department */
  AddEditDepartment(model: any) {
    return this.apiService.postData('api/Department/AddEditDepartment', model, null);
  }
  DeleteDepartmentById(id: number) {
    return this.apiService.postData('api/Department/DeleteDepartmentById?id=' + id, null, null);
  }
  GetAllDepartment(model: any) {
    return this.apiService.postData('api/Department/GetAllDepartment', model, null);
  }
  GetDepartmentById(id: number) {
    return this.apiService.postData('api/Department/GetDepartmentById?id=' + id, null, null);
  }
  //service Sheduling

  GetEventTypeList(model: any) {
    return this.apiService.postData('api/EventType/GetAllEventType', model, null);
  }


  /**Get User profile image  */

  getUserProfileImage() {
    return this.userProfileData.asObservable();
  }
  saveUserProfileImage(data) {
    this.userProfileData.next(data);
  }

  getUserProfile() {
    return this.userUpdateData.asObservable();
  }
  saveUserProfile(data) {
    this.userUpdateData.next(data);
  }

  //Notify
  getUserNotification() {
    return this.notificationData.asObservable();
  }
  saveUserNotification(data) {
    this.notificationData.next(data);
  }

  //wprk order unit Details
  getUnitDetails() {
    return this.unitDetails.asObservable();
  }
  saveUnitDetails(model) {
    this.unitDetails.next(model);
  }

  cleareNotify(data) {
    this.notificationData = data;
  }

  //*********TimeSheet 
  GetTimeSheetByUser(model: any) {
    return this.apiService.postData('api/Timesheet/GetTimeSheetByUser', model, null);
  }
  GetTimeSheet(model: any) {
    return this.apiService.postData('api/Timesheet/GetTimeSheet', model, null);
  }
  GetTimeSheetByUserByWO(model: any) {
    return this.apiService.postData('api/Timesheet/GetTimeSheetByUserByWO', model, null);
  }
  GetTimeSheetReport(model: any) {
    return this.apiService.postData('api/Timesheet/GetTimeSheetReport', model, null);
  }
  GetNewTimeReportList() {
    return this.apiService.getData('api/Timesheet/GetNewTimeReportList', null);
  }
  UpdateClockOutTime(model: any) {
    return this.apiService.postData('api/Timesheet/UpdateClockOutTime', model, null);
  }

  //*********TimeSheet Job Category

  GetAllJobCategories(model: any) {
    return this.apiService.postData('api/Timesheet/GetAllCategories', model, null);
  }
  GetJobCategoryById(id: number) {
    return this.apiService.getData('api/Timesheet/GetCategoryById?id=' + id, null);
  }
  AddEditJobCategory(model: any) {
    return this.apiService.postData('api/Timesheet/AddEditCategory', model, null);
  }
  DeleteJobCategory(id: number) {
    return this.apiService.postData('api/Timesheet/DeleteCategory?id=' + id, null, null);
  }
  ActivateJobCategory(id: number) {
    return this.apiService.postData('api/Timesheet/ActivateCategory?id=' + id, null, null);
  }

  //*********TimeSheet PayrollPeriod

  GetAllPayrollPeriods(model: any) {
    return this.apiService.postData('api/Timesheet/GetAllPayrollPeriods', model, null);
  }
  GetPayrollPeriodById(id: number) {
    return this.apiService.getData('api/Timesheet/GetPayrollPeriodById?id=' + id, null);
  }
  AddEditPayrollPeriod(model: any) {
    return this.apiService.postData('api/Timesheet/AddEditPayrollPeriod', model, null);
  }
  DeletePayrollPeriod(id: number) {
    return this.apiService.postData('api/Timesheet/DeletePayrollPeriod?id=' + id, null, null);
  }
  DefaultPayrollPeriod(id: number, isDefault) {
    return this.apiService.postData('api/Timesheet/DefaultPayrollPeriod?id=' + id + '&isDefault=' + isDefault, null, null);
  }

  //*********TimeSheet WorkWeek Period

  GetAllWorkWeekPeriods(model: any) {
    return this.apiService.postData('api/Timesheet/GetAllWorkWeekPeriods', model, null);
  }
  GetWorkWeekPeriodById(id: number) {
    return this.apiService.getData('api/Timesheet/GetWorkWeekPeriodById?id=' + id, null);
  }
  AddEditWorkWeekPeriod(model: any) {
    return this.apiService.postData('api/Timesheet/AddEditWorkWeekPeriod', model, null);
  }
  DeleteWorkWeekPeriod(id: number) {
    return this.apiService.postData('api/Timesheet/DeleteWorkWeekPeriod?id=' + id, null, null);
  }
  DefaultWorkWeekPeriod(id: number, isDefault) {
    return this.apiService.postData('api/Timesheet/DefaultWorkWeekPeriod?id=' + id + '&isDefault=' + isDefault, null, null);
  }

  //*********TimeSheet Overtime Calculation Method

  GetAllOvertime(model: any) {
    return this.apiService.postData('api/Timesheet/GetAllOvertime', model, null);
  }
  GetOvertimeById(id: number) {
    return this.apiService.getData('api/Timesheet/GetOvertimeById?id=' + id, null);
  }
  AddEditOvertime(model: any) {
    return this.apiService.postData('api/Timesheet/AddEditOvertime', model, null);
  }
  DeleteOvertime(id: number) {
    return this.apiService.postData('api/Timesheet/DeleteOvertime?id=' + id, null, null);
  }
  DefaultOvertime(id: number, isDefault) {
    return this.apiService.postData('api/Timesheet/DefaultOvertime?id=' + id + '&isDefault=' + isDefault, null, null);
  }

}